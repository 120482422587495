.header {
  background-color: #0069A0;
  height: 10vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  border-bottom-color: #CCCCCC;
  border-bottom-width: 6px;
  border-bottom-style: solid;
  top: 0;
  position: absolute;
}

@media only screen and (max-width: 600px) {
  .header {
    justify-content: center;
  }
}

.logo {
  width: 4vh;
  height: 4vh;
  margin-right: 5px;
  margin-left: 20px;
}