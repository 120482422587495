.logoValidate {
  height: 76px;
  width: 76px;
  margin-bottom: 15px;
}

.logoFile {
  height: 36px;
  width: 36px;
  margin-right: 10px;
}

@media only screen and (max-width: 600px) {
  .logoValidate {
    height: 54px;
    width: 54px;
    margin-bottom: 15px;
  }
  .logoFile {
    height: 36px;
    width: 36px;
    margin-right: 10px;
  }
}

.Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border-width: 2px;
  border-style: solid;
  border-color: #c8c8c8;
  border-radius: 7px; */
  width: auto;
  min-width: 280px;
  padding: 10px;
}

.ContainerTop {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
}

.title {
  font-size: 18pt;
}

.label {
  font-size: 10pt;
  font-weight: bold;
  margin-right: 5px;
  width: 120px;
  text-align: right;
}

.labelData {
  font-size: 8pt;
}

.ContainerBottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
}

.ContainerLabels {
  display: flex;
  flex-direction: row;
  align-items: center;
}

a {
  border-width: 0.5px;
  border-style: unset;
  border-color: #0069A0;
  background: #0069A0;
  border-radius: 7px;
  margin: 0 30px 0 30px;
  width: auto;
  padding: 10px 0 10px 0;
  text-decoration: none;
  color: white;
}

a:hover {
  background: #3797ca;
}