.App {
  display: flex;
  width: 100vw;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  background-color: #F8F8F8;
  text-align: center;
  min-height: 100vh;
}
#body{
 width: 100vw;
}
